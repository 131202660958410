const columnNames = {
  id: "Id",
  name: "Name",
  best_time: "Best Time",
  keyword: "Keyword",
  phone_number: "Phone Number",
  address1: "Address",
  city: "City",
  state: "State",
  postal_code: "Postal Code",
  est_age: "Estimated Age",
  birth_month: "Birth Month",
  est_income: "Estimated Income",
  county: "County",
  campaign: "Campaign",
  got_on: "Got On",
  rep_name: "Representative Name",
  activity_log: "Activity Log",
  recording: "Recording",
  lead_type: "Lead Type",
  lead_created_data: "Lead Created Data",
  status: "Status",
  gender: "Gender",
  date: "Date",
  time: "Time",
  notes: "Notes",
  email_address: "Email Address",
  best_day: "Best Day",
  file: "File",
};

const getFormattedName = (name) => {
  if (columnNames[name]) return columnNames[name];
  return name;
};

export { columnNames, getFormattedName };
