import axios from "axios";
import Vue from "vue";
import store from "../store/index";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.response.use(null, (error) => {
  if (error.response.status == 401) {
    store.commit(
      "SET_LOGIN_PAGE_ERROR",
      "Session expired, please login again."
    );
    store.commit("LOGOUT");
  }
  return Promise.reject(error);
});
Vue.prototype.$axios = axios;

export default axios;
