<template>
  <div class="page-wrapper login-wrapper">
    <img class="logo" src="@/assets/logo.jpg" alt="" />
    <form action="" class="login-form bg-primary" @submit="login">
      <input class="text" type="email" placeholder="Email" v-model="email" />
      <input
        class="text"
        type="password"
        placeholder="Password"
        v-model="password"
      />
      <input
        class="login-btn"
        type="submit"
        value="Login"
        :disabled="loading"
      />
    </form>
    <p class="error" v-if="error">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Landing",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  computed: {
    error() {
      return this.$store.getters.loginPageError;
    },
  },
  methods: {
    async login(e) {
      e.preventDefault();
      if (!this.email || !this.password) return;
      this.loading = true;
      try {
        let response = await this.$axios.post("login", {
          email: this.email,
          password: this.password,
        });
        this.$store.commit("SET_AUTH_INFO", {
          token: response.data.token,
          email: this.email,
        });
        this.$store.commit("SET_LOGIN_PAGE_ERROR", "");
        this.$router.push({ path: "/dashboard" });
      } catch (e) {
        this.$store.commit(
          "SET_LOGIN_PAGE_ERROR",
          "Invalid credentials, please try again"
        );
      }
      this.loading = false;
    },
  },
};
</script>

<style>
@import "../assets/css/landing.css";
</style>
