<template>
  <div class="page-wrapper dashboard-wrapper">
    <Navbar />
    <SearchFilters @refresh-table="triggerTableRefresh" />
    <Table ref="tableComponent" :url="url" :key="url" />
  </div>
</template>

<script>
import Table from "@/components/dashboard/Table.vue";
import SearchFilters from "@/components/dashboard/SearchFilters.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Dashboard",
  components: { Navbar, SearchFilters, Table },
  data() {
    return {
      url: process.env.VUE_APP_API_URL + "leads",
    };
  },
  methods: {
    triggerTableRefresh() {
      this.$refs.tableComponent.$refs.vuetable.refresh();
    },
  },
};
</script>

<style>
@import "../assets/css/table.css";
@import "../assets/css/filters.css";
@import "../assets/css/export-modal.css";
@import "../assets/css/import-modal.css";
</style>
