<template>
  <div id="app">
    <router-view />
    <div v-if="refreshingAuth" class="auth-overlay">
      <div class="lds-dual-ring"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      refreshingAuth: false,
    };
  },
  created() {
    let currentToken = this.$store.getters["token"];
    let currentEmail = this.$store.state.auth.email;
    let currentRoute = this.$router.currentRoute.name;
    currentRoute != "Landing" ? this.$router.push({ name: "Landing" }) : null;

    if (currentToken) {
      this.refreshingAuth = true;
      this.$axios
        .post("refresh", {}, { headers: { Authorization: currentToken } })
        .then((response) => {
          this.$store.commit("SET_AUTH_INFO", {
            token: response.data.token,
            email: currentEmail,
          });
          this.$router.push(
            currentRoute != "Landing"
              ? { name: currentRoute }
              : { name: "Dashboard" }
          );
          this.refreshingAuth = false;
        })
        .catch(() => (this.refreshingAuth = false));
    } else this.refreshingAuth = false;
  },
};
</script>

<style>
@import "./assets/css/base.css";
</style>
