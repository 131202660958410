<template>
  <div class="modal import-modal">
    <div class="modal-content">
      <div class="close-btn">
        <i class="fa fa-times" @click="closeModal"></i>
      </div>

      <h4 class="import-modal-title">Import a csv file</h4>

      <div class="import-ctrls">
        <input type="file" ref="importFile" @change="handleFileChange" />
        <button class="filter-btn import-btn" @click="handleFileUpload">
          Import <i class="fa fa-file-upload"></i>
        </button>

        <br />
        <center><small>Max file size: 30mb</small></center>
      </div>

      <div class="progress-indicator-wrapper">
        <div class="progress-indicator" :style="loadingProgress"></div>
      </div>

      <center v-if="loading"><div class="lds-dual-ring"></div></center>
      <center v-if="success"><p>Data imported successfully</p></center>
      <center v-if="error">
        <p>
          Failed to import data, please try again <br />
          Check file size and that data is valid
        </p>
      </center>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ImportModal",
  data() {
    return {
      loading: false,
      file: "",
      currentProgress: 0,
      success: false,
      error: false,
    };
  },
  computed: {
    loadingProgress() {
      return `width: ${this.currentProgress}%`;
    },
  },
  methods: {
    closeModal() {
      !this.loading ? this.$emit("close-modal") : null;
    },
    handleFileChange() {
      this.file = this.$refs.importFile.files[0];
    },
    handleProgressChange(progressEvent) {
      this.currentProgress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
    handleFileUpload() {
      if (!this.file) return;
      this.loading = true;
      this.error = false;
      this.success = false;
      let data = new FormData();
      data.append("file", this.file);
      this.$axios
        .post("import", data, {
          onUploadProgress: this.handleProgressChange,
        })
        .then((res) => {
          if (!res.data.message) throw "Failed";
          this.success = true;
          this.$emit("refresh-table");
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
