<template>
  <div class="table-wrapper">
    <div class="table-content">
      <vuetable
        ref="vuetable"
        :api-url="url"
        :css="tableCss"
        row-class="table-row"
        data-path="data"
        pagination-path=""
        :fields="fields"
        :append-params="filters"
        :http-options="{
          headers: {
            Authorization: token,
          },
        }"
        @vuetable:pagination-data="onPaginationData"
        @vuetable:load-error="handleLoadError"
      ></vuetable>
    </div>
    <div class="table-navigation contain-width">
      <VuetablePagination
        ref="pagination"
        :css="paginationCss"
        @vuetable-pagination:change-page="onPageChange"
      ></VuetablePagination>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import Vue from "vue";

export default {
  name: "Table",
  components: { Vuetable, VuetablePagination },
  props: { url: String },
  computed: {
    filters() {
      return this.$store.state.dashboard.filters;
    },
    token() {
      return this.$store.getters.token;
    },
  },
  watch: {
    filters: function() {
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
  },
  methods: {
    handleLoadError(err) {
      if (err.response.status == 401) {
        this.$store.commit(
          "SET_LOGIN_PAGE_ERROR",
          "Session expired, please login again."
        );
        this.$store.commit("LOGOUT");
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onPageChange(page) {
      this.$refs.vuetable.changePage(page);
    },
    reloadTable() {
      this.$refs.vuetable.refresh();
    },
    wrapData(value) {
      return `<span class='data-popup'>${value}</span>`;
    },
  },
  data() {
    return {
      dataUrl: "http://localhost:8000/api/leads",
      fields: [
        {
          title: "Id",
          name: "id",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "id",
          formatter: this.wrapData,
        },
        {
          title: "Name",
          name: "name",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "name",
          formatter: this.wrapData,
        },
        {
          title: "Best Time",
          name: "best_time",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "best_time",
          formatter: this.wrapData,
        },
        {
          title: "Keyword",
          name: "keyword",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "keyword",
          formatter: this.wrapData,
        },
        {
          title: "Phone Number",
          name: "phone_number",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "phone_number",
          formatter: this.wrapData,
        },
        {
          title: "Address",
          name: "address1",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "address1",
          formatter: this.wrapData,
        },
        {
          name: "city",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "city",
          formatter: this.wrapData,
        },
        {
          title: "State",
          name: "state",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "state",
          formatter: this.wrapData,
        },
        {
          title: "Postal Code",
          name: "postal_code",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "postal_code",
          formatter: this.wrapData,
        },
        {
          title: "Estimated Age",
          name: "est_age",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "est_age",
          formatter: this.wrapData,
        },
        {
          title: "Birth Month",
          name: "birth_month",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "birth_month",
          formatter: this.wrapData,
        },
        {
          title: "Estimated Income",
          name: "est_income",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "est_income",
          formatter: this.wrapData,
        },
        {
          title: "County",
          name: "county",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "county",
          formatter: this.wrapData,
        },
        {
          title: "Campaign",
          name: "campaign",
          dataClass: "table-data break-line",
          titleClass: "table-title",
          sortField: "campaign",
          formatter: this.wrapData,
        },
        {
          title: "Got On",
          name: "got_on",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "got_on",
          formatter: this.wrapData,
        },
        {
          title: "Representative Name",
          name: "rep_name",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "rep_name",
          formatter: this.wrapData,
        },
        {
          title: "Activity Log",
          name: "activity_log",
          dataClass: "table-data",
          titleClass: "table-title",
          formatter: this.wrapData,
        },
        {
          title: "Recording",
          name: "recording",
          dataClass: "table-data break-line",
          titleClass: "table-title",
          formatter: this.wrapData,
        },
        {
          title: "Lead Type",
          name: "lead_type",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "lead_type",
          formatter: this.wrapData,
        },
        {
          title: "Lead Creation Date",
          name: "lead_created_data",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "got_on",
          formatter: this.wrapData,
        },
        {
          title: "Status",
          name: "status",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "status",
          formatter: this.wrapData,
        },
        {
          title: "Gender",
          name: "gender",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "gender",
          formatter: this.wrapData,
        },
        {
          title: "Date",
          name: "date",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "date",
          formatter: this.wrapData,
        },
        {
          title: "Time",
          name: "time",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "time",
          formatter: this.wrapData,
        },
        {
          title: "Notes",
          name: "notes",
          dataClass: "table-data break-line",
          titleClass: "table-title",
          formatter: this.wrapData,
        },
        {
          title: "Email Address",
          name: "email_address",
          dataClass: "table-data break-line",
          titleClass: "table-title",
          formatter: this.wrapData,
        },
        {
          title: "Best Day",
          name: "best_day",
          dataClass: "table-data",
          titleClass: "table-title",
          sortField: "best_day",
          formatter: this.wrapData,
        },
        // {
        //   title: "File",
        //   name: "file",
        //   dataClass: "table-data break-line",
        //   titleClass: "table-title",
        // },
      ],
      tableCss: {
        tableHeaderClass: "table-header",
        tableClass: "table table-bordered table-hover",
        loadingClass: "loading",
        ascendingIcon: "fas fa-sort-up",
        descendingIcon: "fas fa-sort-down",
        ascendingClass: "sorted-asc",
        descendingClass: "sorted-desc",
        sortableIcon: "fa fa-sort",
        // detailRowClass: "vuetable-detail-row",
        // handleIcon: "fa fa-bars text-secondary",
      },
      paginationCss: {
        pageClass: "pagination-ctrl",
        linkClass: "pagination-ctrl",
        wrapperClass: "pagination-wrapper",
        activeClass: "pagination-ctrl-active",
        disabledClass: "pagination-ctrl-disabled",
        icons: {
          first: "fas fa-fast-backward",
          prev: "fas fa-step-backward",
          next: "fas fa-step-forward",
          last: "fas fa-fast-forward",
        },
      },
    };
  },
};
</script>

<style></style>
