<template>
  <div class="navbar">
    <img class="logo" src="@/assets/logo.jpg" alt="" />

    <div class="navbar-ctrls">
      {{ email }}
      <button class="logout-btn" @click="logout">Logout</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    email() {
      return this.$store.state.auth.email;
    },
  },
  methods: {
    logout() {
      this.$axios
        .post("logout")
        .then(() => this.$store.commit("LOGOUT"))
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style>
@import "../assets/css/navbar.css";
</style>
