import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import authModule from "./auth.js";
import dashboardModule from "./dashboard.js";
import errorsModule from "./errors.js";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["auth", "dashboard"],
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authModule,
    dashboard: dashboardModule,
    error: errorsModule,
  },
  plugins: [vuexLocal.plugin],
});
