import router from "../router/index";
import axios from "../plugins/axios";

const authModule = {
  state: {
    token: "",
    email: "",
  },
  getters: {
    loggedIn: (state) => (state.token ? true : false),
    token: (state) => (state.token ? `Bearer ${state.token}` : ""),
  },
  mutations: {
    SET_AUTH_INFO(state, payload) {
      state.token = payload.token;
      state.email = payload.email;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${payload.token}`;
    },
    LOGOUT(state) {
      state.token = "";
      state.email = "";
      delete axios.defaults.headers.common["Authorization"];
      router.push({ path: "/" });
    },
  },
  actions: {},
};

export default authModule;
