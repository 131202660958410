<template>
  <div class="filters-section contain-width">
    <h2>Search Options</h2>
    <div class="filters-ctrls">
      <b>Add a search filter for column :</b>
      <select class="available-filters" v-model="newFilterKey">
        <option value="all" selected="selected">All Columns</option>
        <option
          v-for="filter in availableFilters"
          :key="filter"
          :value="filter"
        >
          {{ getFormattedName(filter) }}
        </option>
      </select>
      <button class="filter-btn add-btn" @click="addFilter">
        Add <i class="fas fa-plus"></i>
      </button>
      <button class="filter-btn clear-btn" @click="clearAll">
        Reset <i class="fas fa-redo"></i>
      </button>
    </div>

    <div class="active-filters">
      <div
        class="filter-item"
        v-for="(filter, key) in activeFilters"
        :key="key"
      >
        <div class="title">
          <b>{{ getFormattedName(key) }}</b>
        </div>

        <div class="content">
          <div class="filter-input-wrapper">
            <input
              type="text"
              class="filter-input"
              :placeholder="getFormattedName(key)"
              v-model="activeFilters[key]"
            />
          </div>

          <button class="filter-btn clear-btn" @click="clearFilter(key)">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="filters-section-actions">
      <button class="filter-btn search-btn" @click="setFilters">
        Search <i class="fas fa-search"></i>
      </button>

      <button @click="exportModalOpen = true" class="filter-btn export-btn">
        Export <i class="fas fa-download"></i>
      </button>

      <button @click="importModalOpen = true" class="filter-btn import-btn">
        Import <i class="fas fa-file-upload"></i>
      </button>

      <ExportModal
        @close-modal="exportModalOpen = false"
        v-if="exportModalOpen"
      />

      <ImportModal
        @refresh-table="emitTableRefresh"
        @close-modal="importModalOpen = false"
        v-if="importModalOpen"
      />
    </div>
  </div>
</template>

<script>
import ExportModal from "@/components/dashboard/ExportModal.vue";
import ImportModal from "@/components/dashboard/ImportModal.vue";
import { getFormattedName } from "@/js/variables.js";

export default {
  name: "SearchFilters",
  components: { ExportModal, ImportModal },
  data() {
    return {
      activeFilters: {},
      newFilterKey: "",
      availableFilters: [],
      exportModalOpen: false,
      importModalOpen: false,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  created() {
    this.activeFilters = JSON.parse(
      JSON.stringify(this.$store.state.dashboard.filters)
    );
    this.$axios
      .get("columns", { headers: { Authorization: this.token } })
      .then((response) => {
        this.availableFilters = response.data;
      })
      .catch((err) => {
        console.log("error from search filters");
        console.log(err);
      });
  },
  methods: {
    getFormattedName,
    addFilter() {
      if (!this.newFilterKey) return;
      this.$set(this.activeFilters, this.newFilterKey, "");
    },
    clearFilter(filterKey) {
      this.$delete(this.activeFilters, filterKey);
    },
    setFilters() {
      this.$store.commit(
        "SET_FILTERS",
        JSON.parse(JSON.stringify(this.activeFilters))
      );
    },
    clearAll() {
      this.activeFilters = {};
      this.$store.commit("SET_FILTERS", {});
    },
    emitTableRefresh() {
      this.$emit("refresh-table");
    },
  },
};
</script>

<style></style>
