<template>
  <div class="modal export-modal">
    <div class="modal-content">
      <div class="close-btn">
        <i class="fa fa-times" @click="closeModal"> </i>
      </div>

      <div class="current-export">
        <button
          class="current-export-btn"
          @click="exportCurrent"
          :disabled="loading"
        >
          Export current results
          <i class="fa fa-download"></i>
          <div v-if="loading">
            <div class="lds-dual-ring"></div>
          </div>
        </button>
      </div>

      <div class="recent-exports-wrapper">
        <h4 class="recent-exports-title">Recent Exports</h4>

        <div v-if="loadingPreviousExports" class="lds-dual-ring"></div>
        <div v-else class="recent-exports">
          <small v-if="!previousExports.length">No Recent Exports</small>
          <ExportCard
            v-for="x in previousExports"
            :key="x.id"
            :exportData="x"
            @delete-export="openDeleteModal"
          />
        </div>
      </div>

      <ConfirmModal
        v-if="deleteModalOpen"
        @close="deleteModalOpen = false"
        @confirm="deleteExport"
      >
        Are you sure you want to delete the export ?
      </ConfirmModal>
    </div>
  </div>
</template>

<script>
import ExportCard from "@/components/dashboard/ExportCard.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import axios from "axios";

export default {
  name: "ExportModal",
  components: { ExportCard, ConfirmModal },
  data() {
    return {
      previousExports: [],
      loading: false,
      loadingPreviousExports: false,
      deleteModalOpen: false,
      idToDelete: null,
    };
  },
  mounted() {
    this.loadingPreviousExports = true;
    axios
      .get("exports")
      .then((res) => {
        this.previousExports = res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.loadingPreviousExports = false));
  },
  methods: {
    closeModal() {
      !this.loading ? this.$emit("close-modal") : null;
    },
    openDeleteModal(id) {
      this.idToDelete = id;
      this.deleteModalOpen = true;
    },
    deleteExport() {
      this.$axios
        .delete(`export/${this.idToDelete}`)
        .then(() => {
          this.previousExports = this.previousExports.filter(
            (x) => x.id != this.idToDelete
          );
          this.idToDelete = null;
          this.deleteModalOpen = false;
        })
        .catch((err) => {
          console.log("error");
          console.log(err.response);
        });
    },
    exportCurrent() {
      let filters = this.$store.getters["searchFilters"];
      this.loading = true;
      this.$axios
        .post("export", {
          filters: filters,
        })
        .then((res) => {
          this.previousExports.unshift(res.data);
          window.open(res.data.url);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
