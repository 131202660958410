<template>
  <div class="export-card">
    <div class="export-card-header">
      <div class="title">
        {{ exportData.filename }}
        <br />
        <small>{{ date }}</small>
      </div>
      <div class="actions">
        <a :href="exportData.url" target="_blank">
          <i class="fa fa-download"></i>
        </a>
        <a @click="emitDeleteEvent"><i class="fa fa-trash"></i></a>
      </div>
    </div>

    <div class="export-card-body" v-html="exportFilters" v-if="exportFilters">
      Filters for this export:
      {{ exportFilters }}
    </div>
  </div>
</template>

<script>
import { getFormattedName } from "@/js/variables.js";

export default {
  name: "ExportCard",
  props: { exportData: Object },
  data() {
    return {
      deleteModalOpen: false,
    };
  },
  methods: {
    emitDeleteEvent() {
      this.$emit("delete-export", this.exportData.id);
    },
  },
  computed: {
    date() {
      let date = new Date(this.exportData.created_at);
      return date.toString();
    },
    exportFilters() {
      let filtersJson = JSON.parse(this.exportData.filters);
      let parsedString = "";
      for (let f in filtersJson)
        filtersJson[f]
          ? (parsedString += `${getFormattedName(f)} : ${filtersJson[f]} <br>`)
          : null;
      return parsedString;
    },
  },
};
</script>

<style></style>
