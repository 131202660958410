<template>
  <div class="modal">
    <div class="modal-content confirm-modal">
      <h3><slot></slot></h3>
      <br />
      <div>
        <button class="btn" @click="cancelAction">
          <slot name="cancelText">Cancel</slot>
        </button>
        <button class="btn" @click="confirmAction">
          <slot name="confirmText">Confirm</slot>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  methods: {
    cancelAction() {
      this.$emit("close");
    },
    confirmAction() {
      this.$emit("confirm");
    },
  },
};
</script>

<style></style>
