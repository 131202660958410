const errorsModule = {
  state: {
    loginPageError: "",
  },
  getters: {
    loginPageError: (state) => state.loginPageError,
  },
  mutations: {
    SET_LOGIN_PAGE_ERROR(state, error) {
      state.loginPageError = error;
    },
  },
};

export default errorsModule;
