const dashboardModule = {
  state: {
    filters: {
      county: "",
      state: "",
      est_age: "",
      campaign: "",
      postal_code: "",
    },
  },
  getters: {
    searchFilters: (state) => state.filters,
  },
  mutations: {
    SET_FILTERS(state, newFilters) {
      state.filters = newFilters;
    },
    CLEAR_FILTERS(state) {
      state.filters = {};
    },
  },
};

export default dashboardModule;
